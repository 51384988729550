export const THEMES = {
  light: {
    'background-base': 'rgba(255,255,255,1)',
    'theme-accent': 'rgba(255,51,95,1)',
    'theme-secondary-accent': 'rgba(67,89,169,1)',
    'theme-auxiliary': 'rgb(236,238,239)',
    'theme-text': 'rgba(55,58,60,1)',
    'theme-gradient-1': 'linear-gradient(120deg, #FF335F 0%, #4359A9 100%)',
    'theme-gradient-2': 'linear-gradient(120deg, #FF335F 0%, #4359A9 100%)',
    'blend-mode': 'hard-light, normal',
    'background-24-dp-elevation': 'rgba(236,238,239,1)',
    'background-48-dp-elevation': 'rgba(236,238,239,1)',
    'background-64-dp-elevation': 'rgba(236,238,239,1)',
  },
  dark: {
    'background-base': 'rgba(22,32,44,1)',
    'theme-text': 'rgba(255,255,255,1)',
    'theme-accent': 'rgba(255,82,119,1)',
    'theme-secondary-accent': 'rgba(95,130,255,1)',
    'theme-auxiliary': 'rgb(45,48,65)',
    'theme-gradient-1': 'linear-gradient(120deg, #5F82FF 0%, #FF5277 100%)',
    'theme-gradient-2' : 'linear-gradient(120deg, #FF5277 0%, #5F82FF 100%)',
    'blend-mode': 'multiply, normal',
    'background-24-dp-elevation': 'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #16202C',
    'background-48-dp-elevation': 'rgba(45,48,65,1)',
    'background-64-dp-elevation': 'rgba(45,48,65,1)',
  },
}

export const BREAKPOINTS = {
  "small-viewport": "768px",
  "medium-viewport": "1024px"
}

export const COLOR_MODE_KEY = 'color-mode'
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode'
