import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"

import DarkToggle from "../DarkToggle"
import LogoImage from "../../../static/Logo.svg"
import { BREAKPOINTS } from "../../constants"
import ResumeButton from "../ResumeButton"

const Navbar = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: grid;
  grid-template-columns: 1fr min(1036px, 100%) 1fr;
  height: 80px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  background: var(--color-background-24-dp-elevation);
  background-blend-mode: overlay, normal;

  a {
    text-decoration: none;
    color: var(--color-theme-text);
  }

  .nav-item-active {
    color: var(--color-theme-text);
    /* border-bottom: 4px solid var(--color-theme-accent);
    padding-bottom: 4px; */
    ::after {
      /* transition: all ease-in-out 0.2s; */
      background: none repeat scroll 0 0 var(--color-theme-accent) !important;
      /* content: "";
      display: block;
      padding-top: 4px; */
      transform: translateX(0) !important;
      @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
        margin-top: 2px;
      }
    }
  }

  .navbar {
    grid-column: 2;
    margin: 0 var(--safety-padding);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      li {
        overflow: hidden;
        a {
          transition: color ease-in-out 0.2s;
          :hover {
            color: var(--color-theme-accent);
            ::after {
              transform: translateX(0);
            }
          }
          ::after {
            transition: all ease-in-out 0.2s;
            background: none repeat scroll 0 0
              var(--color-theme-secondary-accent);
            content: "";
            display: block;
            padding-top: 4px;
            width: 100%;
            transform: translateX(-120%);
          }
        }
      }
      > * + * {
        margin-left: 3.375rem;
      }
    }
    .right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      > * + * {
        margin-left: 0.5rem;
      }
    }
  }

  .navlist {
    > ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      padding: 0;
      > * + * {
        margin-left: 2rem;
      }
    }
  }

  .hamburger-menu {
    display: none;
  }

  .mobile-navlist {
    display: none;
    visibility: hidden;
  }

  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    height: 72px;
    .navlist {
      display: none;
      visibility: hidden;
    }
    .mobile-navlist {
      position: fixed;
      top: 0;
      left: -100vw;
      display: grid;
      align-items: center;
      justify-content: start;
      background: var(--color-theme-auxiliary);
      backdrop-filter: blur(4px);
      width: 100vw;
      height: 100vh;
      transition: transform 240ms ease-out;
      /* transition: right 240ms ease-out; */
      visibility: visible;

      > ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        > * + * {
          margin-top: 2rem;
        }
        margin-left: 4rem;
        > li {
          font-size: var(--heading-4);
          a {
            &.nav-item-active {
              border-bottom: solid 3.2px var(--color-theme-accent);
            }
          }
        }
      }
    }
    .hamburger-menu {
      display: inline-block;
      text-decoration: none;
      border: none;
      margin: 0;
      font-size: 1.5rem;
      background: none;
      color: var(--color-theme-accent);
    }
    .close-hamburger-menu {
      display: inline-block;
      position: fixed;
      right: 24px;
      top: 16px;
      text-decoration: none;
      border: none;
      font-size: 2rem;
      background: none;
      transition: transform 240ms ease-out;
      color: var(--color-theme-accent);
      visibility: hidden;
    }
  }
`

const Logo = styled.div`
  height: 36px;
  width: 114.74px;
  background-color: var(--color-theme-text);
  --webkit-mask: url(${LogoImage}) no-repeat center;
  mask: url(${LogoImage}) no-repeat center;
  transition: background-color ease-in-out 0.2s;
  :hover {
    background-color: var(--color-theme-accent);
    animation: hue 8s infinite linear;
  }
`

function Header() {
  const [menuToggleState, menuToggler] = useState(false)
  const toggleMenu = e => {
    const mobileMenu = document.getElementsByClassName("mobile-navlist")[0]
    const closeButton = document.getElementsByClassName(
      "close-hamburger-menu"
    )[0]
    if (menuToggleState) {
      // Menu is not toggled
      mobileMenu.style.setProperty("transform", "translateX(-100vw)")
      menuToggler(false)
    } else {
      // Menu is toggled
      mobileMenu.style.setProperty("transform", "translateX(100vw)")
      closeButton.style.setProperty("visibility", "visible")
      menuToggler(true)
    }
  }

  return (
    <Navbar>
      <div className="navbar">
        <div className="left">
          <Link to="/" aria-label="gizmofalcon logo">
            <Logo></Logo>
          </Link>
          <nav className="navlist">
            <ul>
              <li>
                <Link to="/" activeClassName="nav-item-active">
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/projects"
                  activeClassName="nav-item-active"
                  partiallyActive={true}
                >
                  Projects
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  activeClassName="nav-item-active"
                  partiallyActive={true}
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/about" activeClassName="nav-item-active">
                  About
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="right">
          {/*<ResumeButton onNavbar={true} /> */}
          <DarkToggle />
          <button className="hamburger-menu" onClick={toggleMenu}>
            ☰
          </button>
        </div>
      </div>
      <nav className="mobile-navlist">
        <button className="close-hamburger-menu" onClick={toggleMenu}>
          &times;
        </button>
        <ul>
          <li>
            <Link to="/" activeClassName="nav-item-active">
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              activeClassName="nav-item-active"
              partiallyActive={true}
            >
              Projects
            </Link>
          </li>
          <li>
            <Link
              to="/blog"
              activeClassName="nav-item-active"
              partiallyActive={true}
            >
              Blog
            </Link>
          </li>
          <li>
            <Link to="/about" activeClassName="nav-item-active">
              About
            </Link>
          </li>
          {/*<li>
            <ResumeButton />
          </li>*/}
        </ul>
      </nav>
    </Navbar>
  )
}

export default Header
