import styled from "styled-components"
import { BREAKPOINTS } from "../constants"

export const SectionHeader = styled.h2`
  text-align: center;
  color: var(--color-theme-secondary-accent);
  text-transform: uppercase;
  font-family: "Work Sans", sans-serif;
  font-size: var(--section-title);
  font-weight: bold;
  line-height: 2rem;
  letter-spacing: 0.032em;
  margin-left: var(--safety-padding);
  margin-right: var(--safety-padding);
  margin-top: calc(var(--safety-padding) * 2);
  margin-bottom: var(--safety-padding);
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    text-align: left;
    margin-bottom: calc(var(--safety-padding) / 2);
  }
`
export const GradientText = styled.span`
  background-image: var(--color-theme-gradient-2);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  animation: hue 3s infinite linear;
  @keyframes hue {
    from {
      filter: hue-rotate(0deg);
    }
    to {
      filter: hue-rotate(-360deg);
    }
  }
`
export const Dot = styled.span`
  color: var(--color-theme-secondary-accent);
`
