import React from "react"
import styled from "styled-components"

import DownloadIcon from "../../static/website_assets/DownloadSimple.svg"
import Resume from "../../static/documents/Resume_Sourabh_Daroji.pdf"
import { BREAKPOINTS } from "../constants"

const StyledResumeButton = styled.a`
  overflow: hidden;
  margin-right: 1rem;
  border: 0.12rem solid var(--color-background-48-dp-elevation);
  background: var(--color-background-48-dp-elevation);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    display: ${props => (props.onNavbar ? "none" : "block")};
    visibility: ${props => (props.onNavbar ? "hidden" : "visible")};
    padding: 0;
    border: none;
  }
  transition: color ease-in-out 0.2s;
  /* @keyframes hue {
    from {
      filter: hue-rotate(0deg);
    }
    to {
      filter: hue-rotate(-360deg);
    }
  } */
  :hover {
    animation: hue 8s infinite linear;
    border-color: var(--color-theme-accent);
    color: var(--color-theme-accent);
    background-color: unset;
    /* ::after {
      transform: translateX(0);
    } */
    ::after {
      background-color: var(--color-theme-accent);
    }
  }
  /* ::after {
    transition: all ease-in-out 0.2s;
    background: none repeat scroll 0 0 var(--color-theme-secondary-accent);
    content: "";
    display: block;
    padding-top: 4px;
    width: 100%;
    transform: translateX(-120%);
  } */
  ::after {
    content: ".";
    display: inline-block;
    margin-left: 0.2rem;
    height: calc(var(--paragraph)+"4px");
    width: 20px;
    transition: background-color 240ms ease-in-out;
    background-color: var(--color-theme-text);
    mask: url(${DownloadIcon}) no-repeat center;
  }
`

function ResumeButton(props) {
  return (
    <StyledResumeButton href={Resume} target="_blank" {...props}>
      Resume
    </StyledResumeButton>
  )
}

export default ResumeButton
