import { BREAKPOINTS } from "../constants"

const { createGlobalStyle } = require("styled-components")

const GlobalStyles = createGlobalStyle`

    :root {
        /* Text-size styles */
        /* base size: tags (16px) */
        --hero: 3.94rem;
        --heading-1: 3.94rem;
        --heading-2: 3rem;
        --heading-3: 2.5rem;
        --heading-4: 2rem;
        --heading-5: 1.75rem;
        --paragraph: 1.2rem;
        --aside: 1.25rem;
        --nav--item--text: 1.25rem;
        --hero-mobile: 3.38rem;
        --tags: 1rem;
        --section--title: 1.5rem;
        --paragraph-mobile: 1rem;
        --heading-1-mobile: 2rem;
        --heading-2-mobile: 1.75rem;
        --heading-3-mobile: 1.5rem;
        --heading-4-mobile: 1.25rem;
        --safety-padding: 2rem;

            @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
            --hero: var(--hero-mobile);
            --heading-1: var(--heading-1-mobile);
            --heading-2: var(--heading-2-mobile);
            --heading-3: var(--heading-3-mobile);
            --heading-4: var(--heading-4-mobile);
            --heading-5: 1rem;
            --paragraph: var(--paragraph-mobile);
            --aside: 1.25rem;
            --nav--item--text: 1.25rem;
            --hero-mobile: 3.38rem;
            --tags: 1rem;
            --section--title: 1.5rem;
            --test: 4rem;
            --safety-padding: 1.5rem;
        }
    }

    html {
        box-sizing: border-box;
        * {
            margin: 0
        }
        overflow-x: hidden;
    }

    a {
        text-decoration: none;
        color: var(--color-theme-accent);
        :hover{
            color: var(--color-theme-secondary-accent);
        }
    }

    h1 {
        font-size: var(--heading-1);
	    font-family:"Mosk Ultra Bold", sans-serif;
        font-weight: 900;
	    line-height:calc(var(--heading-1)*1.2);
        letter-spacing: 0.024em;

    }

    h2 {
        font-family: "Mosk Extra Bold", sans-serif;
        font-size: var(--heading-2);
	    line-height:calc(var(--heading-2)*1.2);
        letter-spacing: 0.036em;
    }

    h3 {
        font-family: "Mosk Extra Bold", sans-serif;
        font-size: var(--heading-3);
        line-height: calc(var(--heading-3)*1.2);
        letter-spacing: 0.024em;
    }

    h4 {
        font-family: "Mosk Extra Bold", sans-serif;
        font-size: var(--heading-4);
        line-height: calc(var(--heading-4)*1.2);
        letter-spacing: 0.032em;
    }

    h5 {
        font-family: "Mosk Extra Bold", sans-serif;
        font-size: var(--heading-5);
        line-height: calc(var(--heading-5)*1.2);
        letter-spacing: 0.032em;
    }

    p {
        font-family: "Work Sans", sans-serif;
        font-size: var(--paragraph);
        line-height: calc(var(--paragraph)*1.6);
        letter-spacing: 0.032em;
    }

    body {
        background: var(--color-background-base);
        font-family: "Work Sans", sans-serif;
        font-size: var(--paragraph);
        line-height: calc(var(--paragraph)*1.4);
        letter-spacing: 0.032em;
        ::-webkit-scrollbar {
            width: 0.32em;
        }
        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px var(--color-background-base);
        }
        ::-webkit-scrollbar-thumb {
            background-color: var(--color-theme-accent);
            border-radius: 1rem;
        }
    }

`

export default GlobalStyles
