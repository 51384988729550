import React from "react"
import styled from "styled-components"

import { ThemeContext } from "./ThemeContext"
import SunIcon from "../../static/website_assets/SunIcon.svg"
import MoonIcon from "../../static/website_assets/MoonIcon.svg"

const ThemeToggleIcon = styled.input`
  appearance: none;
  content: ".";
  display: inline-block;
  margin: 0;
  height: 24px;
  width: 24px;
  background-color: var(--color-theme-text);
  --webkit-mask: url(${SunIcon}) no-repeat center;
  mask: url(${SunIcon}) no-repeat center;
  :checked {
    --webkit-mask: url(${MoonIcon}) no-repeat center;
    mask: url(${MoonIcon}) no-repeat center;
  }
`
const HiddenLabel = styled.label`
  display: inline-block;
  position: fixed;
  top: 0;
  left: 300vw;
`

const DarkToggle = () => {
  const { colorMode, setColorMode } = React.useContext(ThemeContext)

  if (!colorMode) {
    return null
  }

  return (
    <>
      <ThemeToggleIcon
        type="checkbox"
        checked={colorMode === "dark"}
        onChange={ev => {
          setColorMode(ev.target.checked ? "dark" : "light")
        }}
        id="themeToggle"
      />
      <HiddenLabel htmlFor="themeToggle">
        {colorMode}
      </HiddenLabel>
    </>
  )
}

export default DarkToggle
