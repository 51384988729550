import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import FooterLogoImage from "../../../static/Logo-large.svg"
import EmailIcon from "../../../static/website_assets/EnvelopeSimple.svg"
import InstagramIcon from "../../../static/website_assets/InstagramLogo.svg"
import LinkedinIcon from "../../../static/website_assets/LinkedinLogo.svg"
import RSSIcon from "../../../static/website_assets/RssSimple.svg"
import TwitterIcon from "../../../static/website_assets/TwitterLogo.svg"

const FooterWrapper = styled.footer`
  display: grid;
  place-items: center;
  margin-top: 16rem;
  margin-bottom: 3rem;
`
const FooterLogo = styled.div`
  width: 273.5px;
  height: 39.92px;
  background-color: var(--color-theme-text);
  --webkit-mask: url(${FooterLogoImage}) no-repeat center;
  mask: url(${FooterLogoImage}) no-repeat center;
`
const FooterLink = styled.div`
  content: "";
  width: 48px;
  height: 48px;
  background-color: var(--color-theme-text);
  --webkit-mask: url(${props => props.src}) no-repeat center;
  mask: url(${props => props.src}) no-repeat center;
  mask-size: 75%;
  transition: background-color 120ms ease-in-out;
  :hover {
    background-color: var(--color-theme-accent);
  }
`
const FooterSocial = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  opacity: 0.72;
`

const FooterInfo = styled.p`
  font-size: calc(var(--paragraph) * 0.8);

`

export const FooterLinks = props => {
  return (
    <FooterSocial>
      <a
        target="_blank"
        rel="noreferrer"
        href="mailto:darojisourabh@ymail.com?subject=Glanced you portfolio, let's get in touch."
        alt="email Sourabh"
        aria-label="email Sourabh"
      >
        <FooterLink src={EmailIcon} />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/sourabh_daroji/"
        alt="Link to Sourabh's Instagram"
        aria-label="Link to Sourabh's Instagram"
      >
        <FooterLink src={InstagramIcon} />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.linkedin.com/in/sourabhdaroji/"
        alt="Link to Sourabh's LinkedIn Profile"
        aria-label="Link to Sourabh's LinkedIn Profile"
      >
        <FooterLink src={LinkedinIcon} />
      </a>
      {!props.skipRss && (
        <a
          target="_blank"
          rel="noreferrer"
          href="/feed.xml"
          alt="Link to Sourabh's RSS Feed"
          aria-label="Link to Sourabh's RSS Feed"
        >
          <FooterLink src={RSSIcon} />
        </a>
      )}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://twitter.com/gizmofalcon"
        alt="Link to Sourabh's Twitter Profile"
        aria-label="Link to Sourabh's Twitter Profile"
      >
        <FooterLink src={TwitterIcon} />
      </a>
    </FooterSocial>
  )
}

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  return (
    <FooterWrapper>
      <FooterLogo />
      <FooterLinks />
      <p>© {data.site.siteMetadata.author} 2023.</p>
      <FooterInfo>Designed and developed by {data.site.siteMetadata.author}.</FooterInfo>
    </FooterWrapper>
  )
}

export default Footer
