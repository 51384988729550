import React from "react"
import styled from "styled-components"
import Footer from "../components/footer/footer"
import Header from "../components/header/header"
import { BREAKPOINTS } from "../constants"

import LineBg from "../../static/website_assets/line_bg.svg"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr min(1036px, 100%) 1fr;
  margin-bottom: auto;
  > * {
    grid-column: 2;
    :not(section) {
      margin-left: var(--safety-padding);
      margin-right: var(--safety-padding);
      margin-top: calc(var(--safety-padding) / 4);
      margin-bottom: var(--safety-padding);
    }
  }
  > h1,
  > h2,
  > h3,
  > h4,
  > h5 {
    :not(p) {
      margin-top: calc(var(--safety-padding) * 1.5);
    }
    margin-bottom: calc(var(--safety-padding) / 4);
  }
  > p {
    margin-bottom: calc(var(--safety-padding) / 4);
  }
  .title_header {
    margin-top: 1.6rem;
  }
  .full-bleed {
    width: 100%;
    grid-column: 1/4;
    margin: 1rem 0;
    &.add-padding {
      width: auto;
      padding: 0rem 1rem;
      @media (min-width: ${BREAKPOINTS["small-viewport"]}) {
        width: 80vw;
        padding: 0 10vw;
      }
      @media (max-width: ${BREAKPOINTS["medium-viewport"]}) {
        width: 92vw;
        padding: 0 4vw;
      }
    }
  }
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    > * {
      margin-top: 2.25rem;
    }
  }
  figure {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    figcaption {
      text-align: center;
      margin: 0.5rem;
      font-style: italic;
      font-size: calc(var(--tags) - 2px);
    }
  }
  /* ::after {
    display: ${props => (props.showpattern ? "block" : "none")};
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    will-change: background;
    opacity: 0.24;
    background: repeat url(${LineBg});
    background-size: cover;
    animation: hue 12s infinite linear;
  } */
`

const LayoutStyle = styled.div`
  background: var(--color-background-base);
  color: var(--color-theme-text);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  margin-top: 80px;
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    margin-top: 70px;
    min-height: calc(100vh - 70px);
  }
`

function Layout({ children, ...rest }) {
  return (
    <div {...rest}>
      <Header />
      <LayoutStyle>
        <Wrapper {...rest}>{children}</Wrapper>
        <Footer />
      </LayoutStyle>
    </div>
  )
}

export default Layout
