import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

function Head(props) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          googleAnalyticsId
        }
      }
    }
  `)
  return (
    <Helmet
      title={`${props.title} | ${data.site.siteMetadata.title}`}
      htmlAttributes={{ lang: "en" }}
    >
      <meta
        name="description"
        content={`${data.site.siteMetadata.title} - ${props.title}`}
      />
      {/* <meta name="image" content={props.image} />
      <meta property="og:image" content={props.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={props.image} /> */}
      {/* Global site tag (gtag.js) - Google Analytics  */}
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${data.site.siteMetadata.googleAnalyticsId}`}
      ></script>
      <script type="text/javascript">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${data.site.siteMetadata.googleAnalyticsId}");
        `}
      </script>
    </Helmet>
  )
}

export default Head
