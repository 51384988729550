import NewTechstack from "../../../../static/blog_assets/tech_stack.svg";
import ContentWorkflow from "../../../../static/blog_assets/content-workflow.svg";
import CodeWorkflow from "../../../../static/blog_assets/code-workflow.svg";
import OverallArch from "../../../../static/blog_assets/overall-architecuture.svg";
import * as React from 'react';
export default {
  NewTechstack,
  ContentWorkflow,
  CodeWorkflow,
  OverallArch,
  React
};