import RoleIcon from "../../../../static/website_assets/RoleIcon.svg";
import PlatformIcon from "../../../../static/website_assets/Platform.svg";
import DurationIcon from "../../../../static/website_assets/DurationIcon.svg";
import TeamIcon from "../../../../static/website_assets/TeamIcon.svg";
import * as React from 'react';
export default {
  RoleIcon,
  PlatformIcon,
  DurationIcon,
  TeamIcon,
  React
};