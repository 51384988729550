/**
 * Write-only the password as cookie
 */
import React, { useState } from "react"
import { setSessionPassword } from "@mkitio/gatsby-theme-password-protect/src/utils/utils"
import styled from "styled-components"
import Layout from "../../../layouts/layout"
import Head from "../../../components/head"
import { SectionHeader } from "../../../components/Common"
import { FooterLinks } from "../../../components/footer/footer"
import { BREAKPOINTS } from "../../../constants"

const LoginCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: var(--color-background-48-dp-elevation);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
  transition: filter 240ms ease-in-out;
  > * + * {
    margin: 0;
    margin-top: 0.5rem;
  }
  :hover {
    filter: drop-shadow(8px 8px 8px rgb(0 0 0 / 12%)) saturate(120%);
  }
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    padding: 0.8rem;
  }
`
const PasswordInput = styled.input`
  border-radius: 4px;
  border: 2px solid var(--color-theme-auxiliary);
  padding: 0.5rem;
  background-color: var(--color-theme-auxiliary);
  color: var(--color-theme-text);
  filter: brightness(80%);
  :active,
  :focus,
  ::focus-visible {
    border: 2px solid var(--color-theme-secondary-accent);
  }
  margin: 0.5rem;
  flex-grow: 1;
`

const PasswordButton = styled.button`
  background-color: var(--color-theme-auxiliary);
  padding: 0.5rem 1rem;
  color: var(--color-theme-accent);
  border-radius: 4px;
  border: 2px solid var(--color-theme-accent);
  margin: 0.5rem;
  cursor: pointer;
  :hover {
    filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 12%)) saturate(120%);
  }
`
const CustomP = styled.p`
  margin-left: 0.5rem;
`

const PasswordForm = styled.form`
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  width: -moz-available;
  @media (max-width: ${BREAKPOINTS["small-viewport"]}) {
    flex-direction: column;
  }
`

const PasswordProtect = () => {
  const [password, setPassword] = useState("")
  const onSubmit = event => {
    event.preventDefault()
    setSessionPassword(password)
    window.location.reload() // eslint-disable-line
  }

  return (
    <Layout>
      <Head title="Protected Content" />
      <SectionHeader>Protected Content</SectionHeader>
      <LoginCard>
        <PasswordForm onSubmit={onSubmit}>
          <PasswordInput
            name="password"
            type="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
            placeholder="Enter access code"
          />
          <PasswordButton type="submit">Enter</PasswordButton>
        </PasswordForm>
        <CustomP>Feel free to ping me to get an access code!</CustomP>
        <FooterLinks skipRss={true} />
      </LoginCard>
    </Layout>
  )
}

export default PasswordProtect
