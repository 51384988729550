import OldWorkflow from "../../../../src/projects/ouat/images/old-workflow.svg";
import NewWorkflow from "../../../../src/projects/ouat/images/new-workflow.svg";
import InformationArchitecture from "../../../../src/projects/ouat/images/information-architecture.svg";
import Wireframes from "../../../../src/projects/ouat/images/wireframes.svg";
import * as React from 'react';
export default {
  OldWorkflow,
  NewWorkflow,
  InformationArchitecture,
  Wireframes,
  React
};