import React from "react"

import App from "./src/components/App"

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}

export const wrapRootElement = ({ element }) => {
  return <App>{element}</App>
}
