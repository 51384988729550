module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sourabh Daroji's Portfolio","short_name":"Sourabh Daroji","start_url":"/","background_color":"#16202c","theme_color":"#ff5277","display":"standalone","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2b8b9ff2e69e522675739b395574e671"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@mkitio/gatsby-theme-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"password":"piedpiper","partialMatching":true,"pagePaths":["/projects/protected"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/vercel/path0/node_modules/gatsby-remark-images","id":"3f82186d-954b-5082-a3fe-82e07ab79ff1","name":"gatsby-remark-images","version":"5.4.1","pluginOptions":{"plugins":[],"backgroundColor":"transparent","maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/vercel/path0/node_modules/gatsby-remark-images-medium-zoom","id":"5fc13978-fc20-59a4-ab8a-fc90fb417fdc","name":"gatsby-remark-images-medium-zoom","version":"1.7.0","pluginOptions":{"plugins":[],"background":"var(--color-background-base)","zIndex":1000},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":["gatsby-remark-normalize-paths","gatsby-remark-unwrap-images",{"resolve":"gatsby-remark-images","options":{"maxWidth":1920,"linkImagesToOriginal":false,"showCaptions":true,"backgroundColor":"transparent"}},{"resolve":"gatsby-remark-images-medium-zoom","options":{"background":"var(--color-background-base)","zIndex":1000}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"static/blog_assets","ignoreFileExtensions":["png","jpg","jpeg","bmp","tiff","svg"]}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[],"background":"var(--color-background-base)","zIndex":1000},
    },{
      plugin: require('../../../var/task/node_modules/@vercel/gatsby-plugin-vercel-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
