import React from "react"


import "../../node_modules/normalize.css/normalize.css"
import "../styles/preset.css"


import GlobalStyles from "./GlobalStyles"

import { ThemeProvider } from "./ThemeContext"

function App({ children }) {
  return (
    <ThemeProvider>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

export default App
