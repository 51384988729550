import RoleIcon from "../../../../static/website_assets/RoleIcon.svg";
import PlatformIcon from "../../../../static/website_assets/Platform.svg";
import DurationIcon from "../../../../static/website_assets/DurationIcon.svg";
import TeamIcon from "../../../../static/website_assets/TeamIcon.svg";
import FP_Design_Process from "../../../../static/website_assets/FP_Design_Process.svg";
import RepetitiveIcon from "../../../../static/website_assets/Repeat.svg";
import ChaoticIcon from "../../../../static/website_assets/Chaotic.svg";
import TimeCrunchedIcon from "../../../../static/website_assets/time-crunched.svg";
import FP_UserFlow from "../../../../static/website_assets/FP_UserFlow.svg";
import FP_VersionOne from "../../../../src/projects/filterpixel/images/filterpixel_v1.mp4";
import Logo100xvc from "../../../../src/projects/filterpixel/images/Logo100xvc.svg";
import ThousandPlus from "../../../../src/projects/filterpixel/images/ThousandPlus.svg";
import ProductHunt from "../../../../src/projects/filterpixel/images/ProductHunt.svg";
import * as React from 'react';
export default {
  RoleIcon,
  PlatformIcon,
  DurationIcon,
  TeamIcon,
  FP_Design_Process,
  RepetitiveIcon,
  ChaoticIcon,
  TimeCrunchedIcon,
  FP_UserFlow,
  FP_VersionOne,
  Logo100xvc,
  ThousandPlus,
  ProductHunt,
  React
};